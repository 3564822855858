import React, { useEffect, useState, useRef } from 'react'
import { List } from 'devextreme-react/list'
import axios from 'axios'
import notify from '../../utils/notify'
import { UploadDialog, TimestampDisplay } from '../../components'
import { Button as FormButton } from 'devextreme-react/button'
import { useLocation } from 'react-router-dom'
import { socketGet } from '../../socket'
import { saveAs } from 'file-saver'
import DataGrid, { Column, HeaderFilter, Selection, Paging, Button, FilterRow } from 'devextreme-react/data-grid'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { Popup } from 'devextreme-react/popup'
import { useAuth } from '../../contexts/auth'
interface Warning {
	text: string
}

type LogEntry = {
	time: number
	date: string
	message: string
	meta?: Record<string, unknown>
}

type LogsModalProps = {
	logs: LogEntry[] | null
	visible: boolean
	onClose: () => void
}

export default function Imports() {
	const [items, setItems] = useState<any>([])
	const [users, setUsers] = useState<any>({})
	const [warnings, setWarnings] = useState<Warning[]>([])
	const [text, setText] = useState('')
	const [showDialog, setShowDialog] = useState(false)
	const [refresh, setRefresh] = useState(0)
	const [rowsTitle, setRowsTitle] = useState('Rows Imported')
	const [hideImportedFiles, setHideImportedFiles] = useState(true)
	const [successMessage, setSuccessMessage] = useState<boolean>(false)
	const [selectedLogs, setSelectedLogs] = useState<any[] | null>(null)
	const [showLogsModal, setShowLogsModal] = useState(false)

	const gridRef = useRef<DataGrid | null>(null)

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const process = searchParams.get('process')
	const socket = socketGet()

	const { user } = useAuth()

	useEffect(() => {
		const handlerWarning = (data: any) => {
			const { warning } = data
			const { message, line } = warning
			const text = line > 0 ? `${message} on line ${line}` : message
			setWarnings(warnings => [...warnings, { text }])
		}

		if (socket) {
			socket.on('legacy_import_warning', handlerWarning)

			return () => {
				socket.off('legacy_import_warning', handlerWarning)
			}
		}
	}, [socket, warnings])

	useEffect(() => {
		console.log('refresh', refresh)
		axios
			.get(`${API_FIBER_URL}/imports?process=${process}`)
			.then(response => response.data)
			.then(data => {
				setItems(data.list)
				setUsers(data.users)
				setText(data.text)
				setHideImportedFiles(data.hideImportedFiles)
				if (data.rowsTitle !== null) {
					setRowsTitle(data.rowsTitle)
				}
				document.title = `Fiber :: ${data.text}`
			})
	}, [process, refresh])

	const handleClick = (e: any) => {
		const hash = e.row.data.hash
		axios({
			method: 'get',
			url: `${API_FIBER_URL}/imports/file/${encodeURIComponent(hash)}/download?process=${process}`,
			responseType: 'blob',
		}).then(response => {
			const { data, headers } = response
			const blob = new Blob([data], { type: headers['content-type'] })
			saveAs(blob, e.row.data.file)
		})
	}

	const handleViewLogs = async (uniqueId: string) => {
		setShowLogsModal(true)
		if (!uniqueId) {
			setSelectedLogs([])
			return
		}
		setSelectedLogs(null)
		try {
			const response = await axios.get(`${API_FIBER_URL}/imports/logs?uniqueId=${uniqueId}`)
			if (response.data.success) {
				setSelectedLogs(response.data.results)
			} else {
				setSelectedLogs([])
			}
		} catch (error) {
			console.error('Error fetching logs:', error)
			notify('An error occurred while fetching logs', 'error')
		}
	}

	const LogsModal: React.FC<LogsModalProps> = ({ logs, visible, onClose }) => (
		<Popup
			visible={visible}
			onHiding={onClose}
			dragEnabled={false}
			resizeEnabled={true}
			hideOnOutsideClick={true}
			showCloseButton={true}
			showTitle={true}
			title="Import Logs"
			defaultWidth="60%"
			defaultHeight="70%"
		>
			{logs === null && <LoadIndicator id="large-indicator" height={80} width={80} />}
			{logs !== null && (
				<DataGrid dataSource={logs} height="100%" showBorders={true} allowColumnResizing={true}>
					<Paging defaultPageSize={30} defaultPageIndex={0} />
					<Selection mode="single" />
					<FilterRow visible={true} applyFilter={'onClick'} />
					<Column dataField="message" caption="Message" />
					<Column
						dataField="time"
						caption="Time"
						width={'120px'}
						cellRender={({ data }) => <TimestampDisplay timestamp={data.time}></TimestampDisplay>}
					/>
				</DataGrid>
			)}
			<Button text="Close" onClick={onClose} />
		</Popup>
	)

	if (!text) {
		return (
			<h2 className={'content-block'}>
				<LoadIndicator height={20} width={20} />
			</h2>
		)
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>
				<span className={'dx-icon-upload'} style={{ fontSize: '24px' }}></span> {text}
			</h2>
			<div className={'content-block'}>
				<FormButton
					icon="upload"
					text="Import new file"
					width={'400px'}
					height={'100px'}
					onClick={() => {
						setSuccessMessage(false)
						setWarnings([])
						setShowDialog(true)
					}}
				/>
			</div>
			<div className={'content-block'}>
				{successMessage ? (
					<p style={{ fontSize: '18px', color: 'green' }}>Import process has been completed</p>
				) : null}
			</div>
			<br />
			{warnings.length > 0 && (
				<div className={'content-block'}>
					<h2>Warnings:</h2>
					<List className={'warningList'} items={warnings} displayExpr="text" />
				</div>
			)}
			{!hideImportedFiles && (
				<div className={'content-block'}>
					<h2>Imported files:</h2>
					<DataGrid
						ref={gridRef}
						dataSource={items}
						rowAlternationEnabled={true}
						showBorders={true}
						width="100%"
					>
						<Paging defaultPageSize={15} defaultPageIndex={0} />
						<Selection mode="single" />
						<Column dataField="file" />
						<Column dataField="rows" caption={rowsTitle} width="140" alignment="center" />
						{/* <Column dataField="unique_id" width="300" /> */}
						<Column
							dataField="date"
							width="120"
							alignment="center"
							cellRender={({ data }) => {
								return <TimestampDisplay timestamp={data['time']}></TimestampDisplay>
							}}
						/>
						<Column
							dataField="user"
							width="150"
							alignment="center"
							cellRender={({ data }) => {
								const email = (users[data['user']] ?? '').split('@')[0]
								return user?.email === email ? <strong>{email}</strong> : email
							}}
						/>
						<Column type="buttons" width="100">
							<Button icon="download" hint="Download" onClick={handleClick} />
							{/* <Button icon="info" hint="View Logs" onClick={e => handleViewLogs(e.row.data.unique_id)} /> */}
						</Column>
					</DataGrid>
					<HeaderFilter visible={true} />
				</div>
			)}
			{showLogsModal && (
				<LogsModal logs={selectedLogs} onClose={() => setShowLogsModal(false)} visible={showLogsModal} />
			)}
			{showDialog && (
				<UploadDialog
					onHide={() => setShowDialog(false)}
					onComplete={() => {
						setShowDialog(false)
						notify('File uploaded successfully', 'success')
						setTimeout(() => {
							setSuccessMessage(true)
							setRefresh(refresh + 1)
						}, 5000)
					}}
					onErrorFields={() => {
						notify('Error uploading file, columns missing', 'error')
					}}
				/>
			)}
		</React.Fragment>
	)
}
