import React, { useState, useEffect, useRef } from 'react'
import Popup from 'devextreme-react/popup'
import Button from 'devextreme-react/button'
import TextBox from 'devextreme-react/text-box'
import axios from 'axios'
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid'
import notify from '../../utils/notify'
interface FilterPopupProps {
	visible: boolean
	onClose: () => void
	onApply: (filterStatement: any[]) => void
	dataFilter: string | null
	modelName: string | null
	fieldsDataTypes: string[]
}

type Filter = {
	field: any
	filterValue: any
	selectedFilterOperation: string
}

type DisplayFiltersProps = {
	filters: Filter[]
}

const SaveFilterPopup: React.FC<FilterPopupProps> = ({
	visible,
	onClose,
	onApply,
	dataFilter,
	modelName,
	fieldsDataTypes,
}) => {
	const [savedFilters, setSavedFilters] = useState<any[]>([])
	const [filterName, setFilterName] = useState<string>('')

	const gridRef = useRef<DataGrid | null>(null)

	const deleteRow = async (filterId: string) => {
		try {
			const url = `${API_FIBER_URL}/filters/delete/${filterId}`
			await axios.delete(url)
			setSavedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId))
			notify('Filter deleted successfully', 'success')
		} catch (error) {
			notify('Error deleting filter', 'error')
		}
	}

	const applyFilter = async (filter_criteria: any[]) => {
		onApply(filter_criteria)
	}

	const fetchSavedFilters = async () => {
		try {
			const url = `${API_FIBER_URL}/filters?model=${modelName}`
			const response = await axios.get(url)
			setSavedFilters(response.data.filters)
		} catch (err) {
			notify('Failed to fetch the filters', 'error')
		}
	}

	useEffect(() => {
		fetchSavedFilters()
	}, [modelName])

	const handleSave = async () => {
		if (dataFilter && filterName) {
			try {
				const url = `${API_FIBER_URL}/filters/save`
				await axios.post(url, { name: filterName, statement: dataFilter, model: modelName })
				notify('Filter saved successfully', 'success')
				fetchSavedFilters()
			} catch (error) {
				console.error('Error saving filter:', error)
				notify('Error saving filter', 'error')
			}
			setFilterName('')
		}
	}

	const DisplayFilters: React.FC<DisplayFiltersProps> = ({ filters }) => {
		return (
			<div className="savedFilters-container">
				{filters.map((filter, index) => (
					<div key={index} className="filter-item">
						<div className="filter-details">
							<span className="field">{filter.field}</span>
							<span className="operation">{filter.selectedFilterOperation || 'contains'}</span>
							<span className="value">
								{filter.selectedFilterOperation === 'between' ? (
									<>
										{filter.filterValue[0]?.substring(0, 10)} -{' '}
										{filter.filterValue[1]?.substring(0, 10)}
									</>
								) : fieldsDataTypes[filter.field] === 'date' ? (
									filter.filterValue?.substring(0, 10)
								) : (
									filter.filterValue?.toString()
								)}
							</span>
						</div>
					</div>
				))}
			</div>
		)
	}

	return (
		<Popup
			visible={visible}
			showTitle={true}
			title="Create Filter Statement"
			onHiding={onClose}
			defaultWidth="35%"
			defaultHeight="70%"
			hideOnOutsideClick={true}
			resizeEnabled={true}
		>
			<div className="saveFilterInput">
				<TextBox
					value={filterName}
					onValueChanged={e => setFilterName(e.value)}
					placeholder="Enter filter name"
					disabled={!dataFilter}
					style={{ zoom: 0.9 }}
				/>
				<Button text="Save" onClick={handleSave} disabled={!dataFilter} style={{ zoom: 0.8 }} />
			</div>
			<div>
				{dataFilter ? (
					<div>
						<p>
							<b>Current active filter: </b>
						</p>
						<DisplayFilters filters={JSON.parse(dataFilter)} />
						<br />
					</div>
				) : (
					<p>Please create a filter from the data grid to proceed.</p>
				)}
			</div>
			<DataGrid
				ref={gridRef}
				dataSource={savedFilters}
				rowAlternationEnabled={true}
				showBorders={true}
				width="100%"
			>
				<Selection mode="single" />
				<Column dataField="filter_name" width={200} caption="Saved Filters" />
				<Column
					dataField="check"
					caption=""
					width={200}
					cellRender={({ data }) => (
						<Button text="Apply filter" icon="check" onClick={() => applyFilter(data.filter_criteria)} />
					)}
				/>
				<Column
					dataField="delete"
					caption=""
					width={10}
					cellRender={({ data }) => <Button icon="remove" onClick={() => deleteRow(data.id)} />}
				/>
			</DataGrid>
		</Popup>
	)
}

export default SaveFilterPopup
