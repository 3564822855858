import React, { useEffect, useState, useRef, useCallback } from 'react'
import Box, { Item as BoxItem } from 'devextreme-react/box'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, {
	Column,
	Selection,
	Editing,
	Paging,
	FilterRow,
	Toolbar,
	Item as ToolbarItem,
} from 'devextreme-react/data-grid'
import { Switch } from 'devextreme-react/switch'
import Button from 'devextreme-react/button'
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel'
import { loadData, insertData, editData, deleteRolesData } from '../../utils/model-data'
import axios from 'axios'

export default function Model() {
	const [views, setViews] = useState<any>([])
	const [processes, setProcesses] = useState<any>([])
	const [reports, setReports] = useState<any>([])

	const [groups, setGroups] = useState<any>([])
	const [reportGroups, setReportGroups] = useState<any>([])
	const [processGroups, setProcessGroups] = useState<any>([])
	const [viewGroups, setViewGroups] = useState<any>([])

	const [userStore, setUserStore] = useState()
	const [groupStore, setGroupStore] = useState()
	const [viewsStore, setViewsStore] = useState()
	const [processesStore, setProcessesStore] = useState()
	const [reportsStore, setReportsStore] = useState()

	const [height, setHeight] = useState(0)

	const [user, setUser] = useState({})
	const [group, setGroup] = useState({})
	const [view, setView] = useState({})
	const [process, setProcess] = useState({})
	const [report, setReport] = useState({})

	const usersGridRef = useRef<DataGrid | null>(null)
	const groupsGridRef = useRef<DataGrid | null>(null)
	const viewsGridRef = useRef<DataGrid | null>(null)
	const processesGridRef = useRef<DataGrid | null>(null)
	const reportsGridRef = useRef<DataGrid | null>(null)

	useEffect(() => {
		document.title = 'Fiber :: Roles'
	}, [])

	const selectUser = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setUser(item)
			})
		} else {
			setUser({})
		}
	}, [])

	const selectGroup = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setGroup(item)
			})
		} else {
			setGroup({})
		}
	}, [])

	const selectView = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setView(item)
			})
		} else {
			setView({})
		}
	}, [])

	const selectProcess = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setProcess(item)
			})
		} else {
			setProcess({})
		}
	}, [])

	const selectReport = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setReport(item)
			})
		} else {
			setReport({})
		}
	}, [])

	const changedRoleGroup = (u: any, group: number, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const { user_id, row } = u
			const cmd = e.value ? 'set' : 'unset'
			const uGroups =
				cmd === 'set'
					? setRoleGroup(u?.groups as [], group as never)
					: unsetRoleGroup(u?.groups as [], group as never)
			u.groups = uGroups
			setUser(prevUser => ({ ...(prevUser || {}), groups: uGroups }))
			const grid = usersGridRef.current?.instance
			if (grid) {
				grid.cellValue(row, 'groups', uGroups)
			}
			axios.patch(`${API_FIBER_URL}/users/${user_id}/${cmd}/${group}`)
		}
	}

	const changedRoleView = (folder: string, model: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const g = group as any
			const { id, row } = g
			const cmd = e.value ? 'set' : 'unset'
			const gViews =
				cmd === 'set'
					? setRoleView(g?.views as [], folder, model)
					: unsetRoleView(g?.views as [], folder, model)
			g.views = gViews
			setGroup(prevGroup => ({ ...(prevGroup || {}), views: gViews }))
			const grid = groupsGridRef.current?.instance
			if (grid) {
				grid.cellValue(row, 'views', gViews)
			}
			axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?folder=${folder}&model=${model}`).then(() => {
				viewsGridRef.current?.instance.refresh()
			})
		}
	}

	const changedRoleDenyModifyView = (folder: string, model: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const g = group as any
			const { id, row } = g
			const cmd = e.value ? 'set' : 'unset'
			const gDViews =
				cmd === 'set'
					? setRoleView(g?.deny_modify_views as [], folder, model)
					: unsetRoleView(g?.deny_modify_views as [], folder, model)
			g.deny_modify_views = gDViews
			setGroup(prevGroup => ({ ...(prevGroup || {}), deny_modify_views: gDViews }))
			const grid = groupsGridRef.current?.instance
			if (grid) {
				grid.cellValue(row, 'deny_modify_views', gDViews)
			}
			axios
				.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?folder=${folder}&model=${model}&deny_modify=true`)
				.then(() => {
					viewsGridRef.current?.instance.refresh()
				})
		}
	}

	const changedGroupsRoleView = (g: any, folder: string, model: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const cmd = e.value ? 'set' : 'unset'
			const { id, views } = g
			const gViews =
				cmd === 'set' ? setRoleView(views as [], folder, model) : unsetRoleView(views as [], folder, model)
			g.views = gViews
			setViewGroups((prevViewGroups: any[]) => {
				const updatedViewGroups = prevViewGroups.map(viewGroup => {
					if (viewGroup.id === id) {
						return { ...viewGroup, views: gViews }
					}
					return viewGroup
				})
				return updatedViewGroups
			})
			axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?folder=${folder}&model=${model}`).then(() => {
				groupsGridRef.current?.instance.refresh()
			})
		}
	}

	const changedGroupsRoleDenyModifyView = (g: any, folder: string, model: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const cmd = e.value ? 'set' : 'unset'
			const { id, deny_modify_views } = g
			const gDViews =
				cmd === 'set'
					? setRoleView(deny_modify_views as [], folder, model)
					: unsetRoleView(deny_modify_views as [], folder, model)
			g.deny_modify_views = gDViews
			setViewGroups((prevViewGroups: any[]) => {
				const updatedViewGroups = prevViewGroups.map(viewGroup => {
					if (viewGroup.id === id) {
						return { ...viewGroup, deny_modify_views: gDViews }
					}
					return viewGroup
				})
				return updatedViewGroups
			})
			axios
				.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?folder=${folder}&model=${model}&deny_modify=true`)
				.then(() => {
					groupsGridRef.current?.instance.refresh()
				})
		}
	}

	const changedRoleProcess = (process: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const g = group as any
			const { id, row } = g
			const cmd = e.value ? 'set' : 'unset'
			const gProcesses =
				cmd === 'set'
					? setRoleProcess(g?.processes as [], process)
					: unsetRoleProcess(g?.processes as [], process)
			g.Processes = gProcesses
			setGroup(prevGroup => ({ ...(prevGroup || {}), processes: gProcesses }))
			const grid = groupsGridRef.current?.instance
			if (grid) {
				grid.cellValue(row, 'processes', gProcesses)
			}
			axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?process=${process}`).then(() => {
				processesGridRef.current?.instance.refresh()
			})
		}
	}

	const changedGroupsRoleProcess = (g: any, process: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const cmd = e.value ? 'set' : 'unset'
			const { id, processes } = g
			const gProcesses =
				cmd === 'set' ? setRoleProcess(processes as [], process) : unsetRoleProcess(processes as [], process)
			g.processes = gProcesses
			setProcessGroups((prevProcessGroups: any[]) => {
				const updatedProcessGroups = prevProcessGroups.map(processGroup => {
					if (processGroup.id === id) {
						return { ...processGroup, processes: gProcesses }
					}
					return processGroup
				})
				return updatedProcessGroups
			})
			axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?process=${process}`).then(() => {
				groupsGridRef.current?.instance.refresh()
			})
		}
	}

	const changedRoleReport = (report: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const g = group as any
			const { id, row } = g
			const cmd = e.value ? 'set' : 'unset'
			const gReports =
				cmd === 'set' ? setRoleReport(g?.reports as [], report) : unsetRoleReport(g?.reports as [], report)
			g.Reports = gReports
			setGroup(prevGroup => ({ ...(prevGroup || {}), reports: gReports }))
			const grid = groupsGridRef.current?.instance
			if (grid) {
				grid.cellValue(row, 'reports', gReports)
			}
			axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?report=${report}`).then(() => {
				reportsGridRef.current?.instance.refresh()
			})
		}
	}

	const changedGroupsRoleReport = (g: any, report: string, e: any) => {
		if (e.event) {
			e.event.stopPropagation()
			const cmd = e.value ? 'set' : 'unset'
			const { id, reports } = g
			const gReports =
				cmd === 'set' ? setRoleReport(reports as [], report) : unsetRoleReport(reports as [], report)
			g.reports = gReports
			setReportGroups((prevReportGroups: any[]) => {
				const updatedReportGroups = prevReportGroups.map(reportGroup => {
					if (reportGroup.id === id) {
						return { ...reportGroup, reports: gReports }
					}
					return reportGroup
				})
				return updatedReportGroups
			})
			axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?report=${report}`).then(() => {
				groupsGridRef.current?.instance.refresh()
			})
		}
	}

	useEffect(() => {
		const handleResize = () => {
			const viewportHeight = window.innerHeight
			setHeight(viewportHeight - 320)
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'user_id',
			load: loadOptions => {
				return loadData(`users`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						if (data.groups) {
							setGroups(data.groups)
						}
						return {
							data: data.users,
							totalCount: -1,
						}
					})
			},
		})
		setUserStore(ds as any)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'id',
			load: loadOptions => {
				return loadData(`roles`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						setViews(data.views)
						setProcesses(data.processes)
						setReports(data.reports)
						return {
							data: data.groups,
							totalCount: -1,
						}
					})
			},
			insert: values => {
				return insertData(`roles`, values)
			},
			update: (key, values: any) => {
				values.key = key
				return editData(`roles`, values)
			},
			remove: key => {
				return deleteRolesData(key)
			},
		})
		setGroupStore(ds as any)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'key',
			load: loadOptions => {
				return loadData(`roles/views`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						if (data.groups) {
							setViewGroups(data.groups)
						}
						return {
							data: data.views,
							totalCount: -1,
						}
					})
			},
		})
		setViewsStore(ds as any)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'key',
			load: loadOptions => {
				return loadData(`roles/processes`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						if (data.groups) {
							setProcessGroups(data.groups)
						}
						return {
							data: data.processes,
							totalCount: -1,
						}
					})
			},
		})
		setProcessesStore(ds as any)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'key',
			load: loadOptions => {
				return loadData(`roles/reports`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						if (data.groups) {
							setReportGroups(data.groups)
						}
						return {
							data: data.reports,
							totalCount: -1,
						}
					})
			},
		})
		setReportsStore(ds as any)
	}, [])

	function hasRoleGroup(groups: [], group_id: never) {
		return groups && groups.indexOf(group_id) > -1
	}

	function setRoleGroup(groups: [], group: never) {
		let newGroups = groups
		if (newGroups === null) {
			newGroups = []
		}
		if (newGroups && newGroups.indexOf(group) === -1) {
			newGroups.push(group)
		}
		return newGroups
	}

	function unsetRoleGroup(groups: [], group: never) {
		const index = groups ? groups.indexOf(group) : -1
		if (index > -1) {
			delete groups[index]
		}
		return groups
	}

	function UserGroups() {
		const u = user as any
		const { name } = u
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						User: {name}
						<p></p>
					</div>
					{groups.map((group: any) => (
						<div className="dx-field" key={group.id}>
							<div className="dx-field-label">{group.name}</div>
							<Switch
								value={hasRoleGroup(u?.groups as [], group.id as never)}
								onValueChanged={e => changedRoleGroup(u, group.id, e)}
							></Switch>
						</div>
					))}
				</div>
			</div>
		)
	}

	function ViewGroups() {
		const v = view as any
		const { folder, model, name, show_deny } = v
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						View: {name}
						<p></p>
					</div>
					{viewGroups.map((g: any) => {
						const hasViewRole = hasRoleView(g?.views as [], folder, model)
						return (
							<div className="dx-field" key={g.id}>
								<div className="dx-field-label">{g.name}</div>
								<Switch
									value={hasViewRole}
									onValueChanged={e => changedGroupsRoleView(g, folder, model, e)}
								></Switch>
								{show_deny && hasViewRole && (
									<div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
										<i
											className="dx-icon dx-icon-lock"
											style={{ fontSize: '20px', color: 'grey', marginRight: '2px' }}
										></i>
										<Switch
											value={hasRoleView(g?.deny_modify_views as [], folder, model)}
											onValueChanged={e => changedGroupsRoleDenyModifyView(g, folder, model, e)}
										></Switch>
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	function ProcessGroups() {
		const p = process as any
		const { key, name } = p
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Process: {name}
						<p></p>
					</div>
					{processGroups.map((g: any) => (
						<div className="dx-field" key={g.id}>
							<div className="dx-field-label">{g.name}</div>
							<Switch
								value={hasRoleProcess(g.processes as [], key)}
								onValueChanged={e => changedGroupsRoleProcess(g, key, e)}
							></Switch>
						</div>
					))}
				</div>
			</div>
		)
	}

	function ReportGroups() {
		const r = report as any
		const { key, name } = r
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Report: {name}
						<p></p>
					</div>
					{reportGroups.map((g: any) => (
						<div className="dx-field" key={g.id}>
							<div className="dx-field-label">{g.name}</div>
							<Switch
								value={hasRoleReport(g.reports as [], key)}
								onValueChanged={e => changedGroupsRoleReport(g, key, e)}
							></Switch>
						</div>
					))}
				</div>
			</div>
		)
	}

	function hasRoleView(views: [], folder: string, model: string) {
		const key = `${folder}/${model}` as never
		return views && (views.indexOf(key) > -1 || views.indexOf(model as never) > -1)
	}

	function setRoleView(views: [], folder: string, model: string) {
		let newViews = views
		if (newViews === null) {
			newViews = []
		}
		const key = `${folder}/${model}` as never
		if (newViews && newViews.indexOf(key) === -1) {
			newViews.push(key)
		}
		return newViews
	}

	function unsetRoleView(views: [], folder: string, model: string) {
		const key = `${folder}/${model}` as never
		let index = views ? views.indexOf(key) : -1
		if (index > -1) {
			delete views[index]
		}
		// backward compatibility
		index = views ? views.indexOf(model as never) : -1
		if (index > -1) {
			delete views[index]
		}
		return views
	}

	function hasRoleProcess(processes: [], process: string) {
		return processes && processes.indexOf(process as never) > -1
	}

	function setRoleProcess(processes: [], process: string) {
		let newProcess = processes
		if (newProcess === null) {
			newProcess = []
		}
		if (newProcess && newProcess.indexOf(process as never) === -1) {
			newProcess.push(process as never)
		}
		return newProcess
	}

	function unsetRoleProcess(processes: [], process: string) {
		let index = processes ? processes.indexOf(process as never) : -1
		if (index > -1) {
			delete processes[index]
		}
		return processes
	}

	function hasRoleReport(reports: [], report: string) {
		return reports && reports.indexOf(report as never) > -1
	}

	function setRoleReport(reports: [], report: string) {
		let newReport = reports
		if (newReport === null) {
			newReport = []
		}
		if (newReport && newReport.indexOf(report as never) === -1) {
			newReport.push(report as never)
		}
		return newReport
	}

	function unsetRoleReport(reports: [], report: string) {
		let index = reports ? reports.indexOf(report as never) : -1
		if (index > -1) {
			delete reports[index]
		}
		return reports
	}

	function Roles() {
		const g = group as any
		const { name } = g
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Group: {name} - Views
					</div>
					{(Object.entries(views) as [string, any[]][]).flatMap(([category, viewList]) =>
						viewList.map((view: any) => {
							const hasViewRole = hasRoleView(g?.views as [], category, view.model)
							return (
								<div className="dx-field" key={category + '/' + view.model}>
									<div className="dx-field-label" style={{ whiteSpace: 'normal' }}>
										{category} / {view.text}
									</div>
									<Switch
										value={hasViewRole}
										onValueChanged={e => changedRoleView(category, view.model, e)}
									></Switch>
									{view.show_deny && hasViewRole && (
										<div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
											<i
												className="dx-icon dx-icon-lock"
												style={{ fontSize: '20px', color: 'grey', marginRight: '2px' }}
											></i>
											<Switch
												value={hasRoleView(g?.deny_modify_views as [], category, view.model)}
												onValueChanged={e => changedRoleDenyModifyView(category, view.model, e)}
											></Switch>
										</div>
									)}
								</div>
							)
						})
					)}
				</div>
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Group: {name} - Processes
					</div>
					{Object.entries(processes).map(([process, text]) => (
						<div className="dx-field" key={process}>
							<div className="dx-field-label">{text as string}</div>
							<Switch
								value={hasRoleProcess(g?.processes as [], process)}
								onValueChanged={e => changedRoleProcess(process, e)}
							></Switch>
						</div>
					))}
				</div>
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Group: {name} - Reports
					</div>
					{Object.entries(reports).map(([report, text]) => (
						<div className="dx-field" key={report}>
							<div className="dx-field-label">{text as string}</div>
							<Switch
								value={hasRoleReport(g?.reports as [], report)}
								onValueChanged={e => changedRoleReport(report, e)}
							></Switch>
						</div>
					))}
				</div>
			</div>
		)
	}

	function deselect() {
		usersGridRef.current?.instance.deselectAll()
		groupsGridRef.current?.instance.deselectAll()
		viewsGridRef.current?.instance.deselectAll()
		processesGridRef.current?.instance.deselectAll()
		reportsGridRef.current?.instance.deselectAll()
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>Roles</h2>
			<TabPanel onTitleClick={() => deselect()}>
				<TabItem title="Users" icon="user">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={1}>
							<DataGrid
								ref={usersGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={userStore}
								remoteOperations={false}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectUser}
							>
								<Paging enabled={false} />
								<Selection mode="single" />
								<FilterRow visible={true} />
								<Column dataField="name" caption="Username" allowSorting={false} />
								<Toolbar>
									<ToolbarItem location="after">
										<Button
											icon="refresh"
											onClick={() => {
												usersGridRef.current?.instance.refresh()
											}}
										/>
									</ToolbarItem>
								</Toolbar>
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">{Object.keys(user).length > 0 && <UserGroups />}</div>
						</BoxItem>
					</Box>
				</TabItem>
				<TabItem title="Groups" icon="group">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={1}>
							<DataGrid
								ref={groupsGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={groupStore}
								remoteOperations={false}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectGroup}
							>
								<Editing
									startEditAction="dblClick"
									mode="cell"
									allowAdding
									allowUpdating
									allowDeleting
								/>
								<Paging enabled={false} />
								<Selection mode="single" />
								<Column
									dataField="name"
									dataType="string"
									caption="Group name"
									allowSorting={false}
								></Column>
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">{Object.keys(group).length > 0 && <Roles />}</div>
						</BoxItem>
					</Box>
				</TabItem>
				<TabItem title="Views" icon="folder">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={2}>
							<DataGrid
								ref={viewsGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={viewsStore}
								remoteOperations={false}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectView}
							>
								<Paging enabled={false} />
								<Selection mode="single" />
								<FilterRow visible={true} />
								<Column dataField="name" caption="View name" dataType="string" allowSorting={false} />
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">{Object.keys(view).length > 0 && <ViewGroups />}</div>
						</BoxItem>
					</Box>
				</TabItem>
				<TabItem title="Processes" icon="upload">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={2}>
							<DataGrid
								ref={processesGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={processesStore}
								remoteOperations={false}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectProcess}
							>
								<Paging enabled={false} />
								<Selection mode="single" />
								<Column dataField="name" caption="Process name" allowSorting={false} />
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">
								{Object.keys(process).length > 0 && <ProcessGroups />}
							</div>
						</BoxItem>
					</Box>
				</TabItem>
				<TabItem title="Reports" icon="chart">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={2}>
							<DataGrid
								ref={reportsGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={reportsStore}
								remoteOperations={false}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectReport}
							>
								<Paging enabled={false} />
								<Selection mode="single" />
								<Column dataField="name" caption="Report name" allowSorting={false} />
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">{Object.keys(report).length > 0 && <ReportGroups />}</div>
						</BoxItem>
					</Box>
				</TabItem>
			</TabPanel>
		</React.Fragment>
	)
}
